import React, {useState } from "react";
import './Checkout.css'
import {  ShippingOptions } from "../../Metods/Metods";

function Checkout() {

  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(null);

  function DeliveryMethodSelection({ onSelectDeliveryMethod }) {

    const handleDeliveryMethodChange = (method) => {
      setSelectedDeliveryMethod(method);
      onSelectDeliveryMethod(method);
    };
  
    return (
      <div className="delivery-method-selection">
        <h2>Seleccione un Método de Entrega:</h2>
        <div className="delivery-method-option">
          <input
            type="radio"
            id="EnvioDomicilio"
            name="deliveryMethod"
            value="EnvioDomicilio"
            checked={selectedDeliveryMethod === "EnvioDomicilio"}
            onChange={() => handleDeliveryMethodChange("EnvioDomicilio")}
          />
          <label htmlFor="EnvioDomicilio">Entrega a Domicilio</label>
        </div>
        <div className="delivery-method-option">
          <input
            type="radio"
            id="RetiroLocalMariana"
            name="deliveryMethod"
            value="RetiroLocalMariana"
            checked={selectedDeliveryMethod === "RetiroLocalMariana"}
            onChange={() => handleDeliveryMethodChange("RetiroLocalMariana")}
          />
          <label htmlFor="RetiroLocalMariana">Retirar en Sucursal</label>
        </div>
        <div className="delivery-method-option">
          <input
            type="radio"
            id="RetiroSucursalCorreoArgentino"
            name="deliveryMethod"
            value="RetiroSucursalCorreoArgentino"
            checked={selectedDeliveryMethod === "RetiroSucursalCorreoArgentino"}
            onChange={() => handleDeliveryMethodChange("RetiroSucursalCorreoArgentino")}
          />
          <label htmlFor="RetiroSucursalCorreoArgentino">Retirar en Correo Argentino</label>
        </div>
        {selectedDeliveryMethod === "EnvioDomicilio" || selectedDeliveryMethod === "RetiroSucursalCorreoArgentino" ? (
          <ShippingOptions selectedDeliveryMethod={selectedDeliveryMethod} onZoneSelected={onSelectDeliveryMethod} />
        ) : selectedDeliveryMethod === "RetiroLocalMariana" ? (
          <>
          <h3>Le contactaremos para coordinar la entrega.</h3>
          <ShippingOptions selectedDeliveryMethod={selectedDeliveryMethod} onZoneSelected={onSelectDeliveryMethod} />
          </>
        ) : null}
       
      </div>
    );
  }
  
  return (
    <div>
    <section className="sectionCheck">
     
       <section>
         <DeliveryMethodSelection onSelectDeliveryMethod={setSelectedDeliveryMethod} />
       </section>

    </section>
   
    <h4 className="pMetodo">*Metodo de pago:
     <p>· Si seleccionas TRANSFERENCIA DIRECTA se te mostraran los datos necesarios para hacer la transferencia.</p>
     <p>· Si seleccionas TARJETA DE CREDITO o MERCADO PAGO debes llenar el formulario de envio y en breve nos contactaremos con vos para finalizar la compra.</p>
    </h4>
    <h4 className="pMetodo">*Envio: <p>· ENVIO A DOMICILIO.</p>
    <p>· RETIRAR EN NUESTROS LOCALES.</p>
    <p>· RETIRAR EN SUCURSAL MAS CERCANA.</p></h4>
    
    </div>
  );
}

export default Checkout;

