import React from 'react';
import './InicioImages.css';
import { Link } from 'react-router-dom';

const InicioImages = () => {
  const imageCategories = [
    { id: 'SacosCamperas', title: 'Sacos Camperas', image:'#ad8a76' },
    { id: 'BlusasCamisas', title: 'Blusas Camisas', image:'#d2b6a1' },
    { id: 'Pantalones', title: 'Pantalones', image:'#ad8a76db '},
    { id: 'Remeras', title: 'Remeras', image:'#d2b6a1' },
    { id: 'ShortsPolleras', title: 'Shorts y Polleras', image:'#d2b6a1' },
    { id: 'Vestidos', title: 'Vestidos', image:'#c8a992' },
    { id: 'Noche', title: 'Noche', image:'#ad8a76' },
    { id: 'HoddiesJoggins', title: 'Hoddies y Joggins', image:'#c8a992' },
    { id: 'Jeans', title: 'Jeans', image:'#ad8a76' },
    { id: 'MonosSet', title: 'Monos y Set', image:'#c8a992' },
    { id: 'TallesReales', title: 'Talles Reales', image:'#d2b6a1' },
    { id: 'Accesorios', title: 'Accesorios', image:'#c8a992' },
    { id: 'Sale', title: 'Sale', image:'#ad8a76db' },
    { id: 'LookMariana', title: 'Look Mariana', image:'#d2b6a1' },
    { id: 'TallesEspeciales', title: 'Talles Especiales', image:'#ad8a76'},
    { id: 'Outlet', title: 'Outlet', image:'#c8a992'}
  ];

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  return (
    <div className="containerAbuelo">
      <div className="contenedorMain">
        {imageCategories.map((category, index) => (
          <div key={index}>
              <Link className="inicio-images" onClick={scrollToTop} to={`/${category.id}`}>
            <div className="categoria-card" style={{ backgroundColor: `${category.image}` }}>
            
                <h3 className="categoria-title">{category.title}</h3>
              
            </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InicioImages;