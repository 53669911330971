import React, { useState, useContext } from 'react';
import ItemCount from './ItemCount';
import './ItemDetail.css';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../../context/cartContext';
import { BsArrowBarLeft } from 'react-icons/bs';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';

const ItemDetail = ({ producto }) => {
  const { addItem } = useContext(CartContext);
  const [quantityAdded, setQuantityAdded] = useState(0); 
  const navigate = useNavigate();

  const handleGoBack = () => {
    // Utiliza navigate(-1) para retroceder una página
    navigate(-1);
  };

  const handleOnAdd = (quantity) => {
    setQuantityAdded(quantity);

    const item = {
      id: producto.id,
      title: producto.title,
      price: producto.price,
      color: producto.color,
      talle: producto.talle,
      peso: producto.peso,
      categoria: producto.category,
      imageUrl1: producto.imageUrl1,
      imageUrl2: producto.imageUrl2, 
      imageUrl3: producto.imageUrl3, 
      description: producto.description
    };
    
    addItem(item, quantity);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  return (
    <section className='sectionDetail'>
      <div className='tituloCarrito'>
        <h3>Estas viendo: {producto.title} </h3>
      </div>
      <div className='contenedorBtnBack'>
        <button className='btnBack' onClick={handleGoBack}><BsArrowBarLeft/>Atras</button>
      </div>
      <div className="ItemDetail">
        <div className='carrouselDiv'>

        <Carousel showThumbs={false}>
          <div className='carrouselHijo'>
          <img src={producto.imageUrl1} alt="1" />
          </div>
          <div>
          <img src={producto.imageUrl2} alt="2" />
          </div>
          <div>
          <img src={producto.imageUrl3} alt="3" />
          </div>
         </Carousel>
        </div>
        <div className='DetailHijo'>
          <h2>{producto.title}</h2>
          <h2>$ {producto.price}</h2>
          <p>{producto.description}</p>
          <p>Talle: {producto.talle}</p>
          <p>Color: {producto.color}</p>
          <p>Peso: {producto.peso}</p>

          <div className='contador'>
            {quantityAdded > 0 ? (
              <section className='btns'>
                <Link onClick={scrollToTop} to='/' className='finish btnSeguir'>Seguir Comprando</Link>
                <Link onClick={scrollToTop} to='/cart' className='finish'>Ir al carrito</Link>
              </section>
            ) : (
              <ItemCount initial={1} stock={producto.stock} onAdd={handleOnAdd} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ItemDetail;