import React, { useState } from "react";
import PaymentMethodSelection from "./PaymentMethodSelection";

function ShippingOptions({ selectedDeliveryMethod}) {
    const [selectedZone, setSelectedZone] = useState(null);
    const [tarifaEnvio, setTarifaEnvio] = useState(0);
    const [showPaymentMethod, setShowPaymentMethod] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);

  const handleShowPaymentMethod = () => {
    setShowPaymentMethod(true);
  };

  // Función para manejar los cambios en la zona
  const handleZoneChange = (zone) => {
    setSelectedZone(zone);

    // Aquí llamamos a setTarifaEnvio y mostramos el valor actual
    if (zone === "Zona 1") {
      setTarifaEnvio(2000);
      console.log(2000);
    } else if (zone === "Zona 2") {
      setTarifaEnvio(2300);
      console.log(2300);
    } else if (zone === "Zona 3") {
      setTarifaEnvio(2500);
      console.log(2500);
    }
  };

  
  return (
    <section>
      <div className="shipping-options">
        {selectedDeliveryMethod === "EnvioDomicilio" && (
          <>
            <h2>Tarifas Correo Argentino</h2>
            <div>
              <input
                type="radio"
                id="zona1"
                name="zoneSelection"
                value="Zona 1"
                onChange={() => handleZoneChange("Zona 1")}
              />
              <label htmlFor="zona1">Envío Clásico | Zona 1</label>
            </div>
            <p>$2.000,00</p>
            <p>Sfe | BsAs | Cba | ER | SL | SdE | F | Cho | Cts</p>
            <div>
              <input
                type="radio"
                id="zona2"
                name="zoneSelection"
                value="Zona 2"
                onChange={() => handleZoneChange("Zona 2")}
              />
              <label htmlFor="zona2">Envío Clásico | Zona 2</label>
            </div>
            <p>$2.300,00</p>
            <p>Sfe | BsAs | Cba | ER | SL | SdE | F | Cho | Cts</p>
            <div>
              <input
                type="radio"
                id="zona3"
                name="zoneSelection"
                value="Zona 3"
                onChange={() => handleZoneChange("Zona 3")}
              />
              <label htmlFor="zona3">Envío Clásico | Zona 3</label>
            </div>
            <p>$2.500,00</p>
            <p>Sfe | BsAs | Cba | ER | SL | SdE | F | Cho | Cts</p>
          </>
        )}

        {selectedDeliveryMethod === "RetiroSucursalCorreoArgentino" && (
          <>
            <h2>Tarifas Correo Argentino</h2>
            <p>Selecciona la zona correspondiente a tu provincia antes de continuar.</p>
            <div>
              <input
                type="radio"
                id="zona1"
                name="zoneSelection"
                value="Zona 1"
                onChange={() => handleZoneChange("Zona 1")}
              />
              <label htmlFor="zona1">Envío Clásico | Zona 1</label>
            </div>
            <p>$2.200,00</p>
            <p>Sfe | BsAs | Cba | ER | SL | SdE | F | Cho | Cts</p>
            <div>
              <input
                type="radio"
                id="zona2"
                name="zoneSelection"
                value="Zona 2"
                onChange={() => handleZoneChange("Zona 2")}
              />
              <label htmlFor="zona2">Envío Clásico | Zona 2</label>
            </div>
            <p>$2.500,00</p>
            <p>Ca | Jy | LP | LR | Mz | Mi | Sa | SJ | Tu</p>
            <div>
              <input
                type="radio"
                id="zona3"
                name="zoneSelection"
                value="Zona 3"
                onChange={() => handleZoneChange("Zona 3")}
              />
              <label htmlFor="zona3">Envío Clásico | Zona 3</label>
            </div>
            <p>$2.700,00</p>
            <p>Cht | Nqn | RN | SC | TdF</p>
          </>
        )}

      </div>

      <section>
        {tarifaEnvio !== 0 && (

          <div className="aclaraciones">
           <p>A los paquetes con un peso superior a 3 kg se les adicionan $600 ARS por cada kilo extra.</p>
           <p>Tarifa de envío seleccionada: ${tarifaEnvio}</p>
          </div>
        )}
      </section>

      <div>
        
      <button className="btnPayment" onClick={handleShowPaymentMethod}>Elegir método de Pago</button>
      {showPaymentMethod && (
          <PaymentMethodSelection
            tarifaEnvio={tarifaEnvio} // Pasa tarifaEnvio como una prop
            onPaymentMethodChange={setSelectedPaymentMethod} 
            selectedDeliveryMethod={selectedDeliveryMethod}
          />
        )}
      </div>

    </section>
  );
}



export { ShippingOptions };
