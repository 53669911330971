import React, { useState } from "react";
import Form from "../components/checkout/Form";

function PaymentMethodSelection({ tarifaEnvio, onPaymentMethodChange, selectedDeliveryMethod }) {
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
    const [showForm, setShowForm] = useState(false);
  
    const handlePaymentMethodChange = (method) => {
      setSelectedPaymentMethod(method);
    };
  
    const handleFinalizarCompraClick = () => {
      // Cuando se haga clic en "Finalizar Compra," mostramos el componente Form y ocultamos los demás
      setShowForm(true);
    };
    return (
        <section>
          {!showForm ? (
            <div className="payment-method-selection">
              <h2>Seleccione un Método de Pago:</h2>
              <div className="payment-method-option">
                <input
                  type="radio"
                  id="TarjetaCredito/MercadoPago"
                  name="paymentMethod"
                  value="TarjetaCredito/MercadoPago"
                  checked={selectedPaymentMethod === "TarjetaCredito/MercadoPago"}
                  onChange={() => {
                    handlePaymentMethodChange("TarjetaCredito/MercadoPago");
                    onPaymentMethodChange("TarjetaCredito/MercadoPago"); // Actualiza selectedPaymentMethod en ShippingOptions
                  }}
                />
                <label htmlFor="TarjetaCredito/MercadoPago">Tarjeta de Crédito / Mercado Pago <p className="cuotas">(3 cuotas sin interés con Tarjetas de Crédito)</p></label>
                
              </div>
              <div className="payment-method-option">
                <input
                  type="radio"
                  id="transferenciaDirecta"
                  name="paymentMethod"
                  value="transferenciaDirecta"
                  checked={selectedPaymentMethod === "transferenciaDirecta"}
                  onChange={() => {
                    handlePaymentMethodChange("transferenciaDirecta");
                    onPaymentMethodChange("transferenciaDirecta"); // Actualiza selectedPaymentMethod en ShippingOptions
                  }}
                />
                <label htmlFor="transferenciaDirecta">Transferencia Directa</label>
              </div>
              <div>
                <button className="btnFin" onClick={handleFinalizarCompraClick}>Finalizar Compra</button>
              </div>
            </div>
          ) : (
         <Form
           tarifaEnvio={tarifaEnvio}
           selectedDeliveryMethod={selectedDeliveryMethod}
           selectedPaymentMethod={selectedPaymentMethod}
           />
          )}
        </section>
      );
}

export default PaymentMethodSelection;